import React from "react";
import Grow from "@material-ui/core/Grow";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import {withStyles} from "@material-ui/core/styles";

const defaultSearchStyles = {
    main: {
        display: "flex",
        flex: "1 0 auto",
    },
    searchIcon: {
        marginTop: "10px",
        marginRight: "8px",
    },
    searchText: {
        flex: "0.8 0",
    },
    clearIcon: {
        "&:hover": {
            color: "#FF0000",
        },
    },
};

class MaterialDatatableSearch extends React.Component {
    handleTextChange = event => {
        const {onSearchChange} = this.props.options;

        if (onSearchChange) {
            onSearchChange(event.target.value);
        }

        this.props.onSearch(event.target.value);
    };

    componentDidMount() {
        document.addEventListener("keydown", this.onKeyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown, false);
    }

    onKeyDown = event => {
        if (event.keyCode === 27) {
            this.props.onHide();
        }
    };

    render() {
        const {classes, options, onHide} = this.props;

        return (
            <Grow appear in={true} timeout={300}>
                <div className={classes.main} ref={el => (this.rootRef = el)}>
                    <SearchIcon className={classes.searchIcon}/>
                    <TextField
                        className={classes.searchText}
                        value={this.props.searchText !== null && this.props.searchText !== null ? this.props.searchText : "" }
                        autoFocus={true}
                        InputProps={{
                            "aria-label": options.textLabels.toolbar.search,
                        }}
                        onChange={this.handleTextChange}
                        fullWidth={true}
                        inputRef={el => (this.searchField = el)}
                    />
                    <IconButton className={classes.clearIcon} onClick={onHide}>
                        <ClearIcon/>
                    </IconButton>
                </div>
            </Grow>
        );
    }
}

export default withStyles(defaultSearchStyles, {name: "MaterialDatatableSearch"})(MaterialDatatableSearch);
